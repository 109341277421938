<template>
  <div class="section-content" :class="{'tenant-section': !isAdmin}">
    <div class="tenant-profile-sticky" :style="{ zIndex: $vuetify.breakpoint.width > 480 ? 4 : showAside ? '2' : '3'  }" :class="{ 'scroll-window tenant-profile-shadow': hasScrollWindow, 'tenant-profile-border': !hasScrollWindow }" v-show="!isStickyHeaderHide" v-if="tenant_contact.id">
      <v-row  class="ma-0 pt-6 pb-4 d-flex align-center px-5">
        <v-col class="ma-0 pa-0 col-xs-12 backtohistory">
          <!-- <hb-breadcrumb v-if="
            $route.query.document_batch_id &&
            $route.query.document_delivery_id
          " :to="{
            name: 'noticeManagerBatchViewDirect',
            params: {
              document_batch_id: $route.query.document_batch_id,
              document_delivery_id: $route.query.document_delivery_id,
            },
          }">
            {{ breadcrumbText }}
          </hb-breadcrumb>
          <hb-breadcrumb v-else-if="$route.query.document_batch_id" :to="{
            name: 'noticeManagerBatchViewDirectBatchIdOnly',
            params: { document_batch_id: $route.query.document_batch_id },
          }">
            {{ breadcrumbText }}
          </hb-breadcrumb> -->
          <hb-breadcrumb @click="goBreadcrumbRoute">{{
            breadcrumbText
          }}</hb-breadcrumb>
        </v-col>
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
      </v-row>
      <!-- MINI PROFILE -->
      <hb-header class="px-5 sticky-miniprofile" one-action :padding="false" :divider="false">
        <template v-slot:left>
          <div class="pr-4" :class="{ 'pb-2': $route.name !== 'ContactFiles'}">
            <hb-mini-profile type="account" :title="tenant_contact.Business.name" :status="tenant_contact.status ? tenant_contact.status : undefined
              " :address="computedBusinessAddress" :id-verified="tenant_contact.email_verified
                ? tenant_contact.email_verified
                : undefined
                " :account-space-count="calculateLength" :account-space-type="tenant_contact.Leases && tenant_contact.Leases?.length === 1
                  ? tenant_contact?.Leases[0]?.Unit?.type
                  : ''
                  " :account-size="accountSize"
              :account-total-monthly-spent="NationalAccount.total_monthly_rent | formatMoney" />
          </div>
        </template>
        <template v-slot:right>
          <div>
            <hb-btn icon :tooltip="!$vuetify.breakpoint.xs ? 'Sidebar' : ''" class="mr-n3" @click="showAside = !showAside" :active-by-default="true"
              :active-state-off="!showAside">mdi-table-actions-custom-1</hb-btn>
          </div>
        </template>
      </hb-header>
      <!-- <v-divider v-if="properties_with_leases.length > 1"></v-divider> -->
      <HbPropertySelector id="facility" :items="properties_with_leases" v-model="propertyView"
        v-if="properties_with_leases.length >= 1" item-value="id" item-text="name" height="40"
        @change="handlePropertyViewChange" class="pl-5" :class="{ 'hb-header-select': $vuetify.breakpoint.mdAndUp }">
        <template v-slot:selection="data">
          <div class="hb-input-overflow" v-if="data.item.number && data.item.name && data.item.Address">
            {{ data.item.number }} - {{ data.item.name }} -
            {{ data.item.Address.address }} {{ data.item.Address.address2 }}
          </div>
          <div v-else>
            {{ data.item.name }}
          </div>
        </template>
        <template v-slot:item="data">
          <div v-if="data.item.number && data.item.name && data.item.Address">
            {{ data.item.number }} - {{ data.item.name }} -
            {{ data.item.Address.address }} {{ data.item.Address.address2 }}
          </div>
          <div v-else>
            {{ data.item.name }}
          </div>
        </template>
      </HbPropertySelector>
      <!-- <v-divider class="mt-3"></v-divider> -->
      <hb-tabs v-model="activeTab" v-if="$route.name === 'NationalDocuments' && this.propertyView != 'all-property'" class="mt-2">
        <v-tab v-for="menuOption in menuOptions" :key="menuOption.key" :href="'#tab-' + menuOption.key"
          @click="view = menuOption.key" :ripple="false">{{ menuOption.label }}</v-tab>
      </hb-tabs>
    </div>

    <div class=" primary-section-content no-scroll"
    :class="[
      !responsiveInteraction ? 'pl-5': '',
      isStickyHeaderHide ? 'pa-0 is-email-container': '',
      ]"
    >
      <div class="main-content" :class="{'pr-5': !responsiveInteraction }">
        <router-view
          v-if="(tenant_contact.id && !showPaymentCyclesTab && $route.name !== 'ContactPaymentCycles') || showPaymentCyclesTab"
          :payment_methods="payment_methods" :access="access" :contact="tenant_contact" :showAside="showAside"
          @moveOut="setMoveOut" @merchandise="setMerchandise" :contactLeases="property_leases" :keyStat="keyStat"
          :isSpaceAccessCodesVendor="isSpaceAccessCodesVendor" :view="view" @fetchContact="fetchContact"
          :pending="pending" @reset="
            activeTab = 'tab-tenantdocuments';
          view = 'tenantdocuments';
          " :property-view.sync="propertyView" @transfer="setTransfer" :from-tenant-profile="true" ref="OverviewRef" @responsiveInteraction="responsiveInteractionAction">
        </router-view>
        <v-progress-circular v-else indeterminate color="primary" class="loading"></v-progress-circular>
      </div>
      <v-navigation-drawer v-if="tenant_contact.id" v-model="showAside" :right="$vuetify.breakpoint.width >= 481"
        :hide-overlay="$vuetify.breakpoint.width > 480" :clipped="$vuetify.breakpoint.width > 480"
        :class="{ 'attached-drawer': $vuetify.breakpoint.width > 480 }" disable-resize-watcher mobile-breakpoint="481"
        class="ma-0 pa-0 hb-tertiary-navigation tenant-profile-sidebar-wrapper" :width="showAside ? '250px' : '0px'"
        :bottom="$vuetify.breakpoint.width <= 480" :app="$vuetify.breakpoint.width <= 480" flat>
        <v-list dense style="width: 100%">
          <v-list-item-group v-model="selection" mandatory>
            <v-list-item v-if="allPropertyView" exact
              :to="'/national-account/' + $route.params.contact_id + `?na_id=${this.$route.query.na_id}&dynamicRun=true`"
              class="pl-5" @click="closeDrawer">
              Account Summary
            </v-list-item>
            <v-list-item exact v-if="!allPropertyView" :to="'/national-account/' +
              $route.params.contact_id +
              `/account-profile?na_id=${this.$route.query.na_id}`
              " class="pl-5" @click="closeDrawer">
              Account Profile
            </v-list-item>
            <v-list-item exact class="pl-5" v-if="allPropertyView" :to="'/national-account/' +
              $route.params.contact_id +
              `/communication?na_id=${this.$route.query.na_id}`
              " @click="closeDrawer">Communication </v-list-item>
            <v-list-item exact class="pl-5" :to="'/national-account/' +
              $route.params.contact_id +
              `/files?na_id=${this.$route.query.na_id}`
              " @click="closeDrawer">Documents </v-list-item>
            <v-list-item class="pl-5" :to="'/national-account/' +
              $route.params.contact_id +
              `/transaction-history?na_id=${this.$route.query.na_id}`
              " @click="closeDrawer">Transaction History </v-list-item>
            <v-list-item v-if="allPropertyView" class="pl-5" exact :to="'/national-account/' +
              $route.params.contact_id +
              `/account-information?na_id=${this.$route.query.na_id}`
              " @click="closeDrawer">
              Account Information
            </v-list-item>
            <!-- <v-list-item class="pl-6" v-if="!allPropertyView">Gate Access</v-list-item> -->
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <v-overlay v-model="overlay"></v-overlay>
  </div>
</template>

<script type="text/babel">
import SendMessage from "../../includes/SendMessage.vue";
import moment from "moment";
import { EventBus } from "../../../EventBus.js";
import sendEmail from "../../communication_center/SendEmail.vue";
import Status from "../../includes/Messages.vue";
import api from "../../../assets/api.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
  name: "ContactView",
  mixins: [notificationMixin],
  data() {
    return {
      keyStat: {
        totalSqm: "",
        prop_or_Space: "",
        discount: "",
        rent: "",
        next_bill_date: "",
        totalBalance: ""
      },
      totalSqm: '',
      monthly_rent: '',
      overlay: false,
      allPropertyView: true,
      contact_id: "",
      contactLeases: [],
      lease: {
        id: null,
        rent: 0,
        security_deposit: 0,
        type: "Fixed Length",
        start_date: "",
        end_date: "",
        billday: "",
        promotion_id: "",
        send_invoice: 1,
        notes: "",
        late_fee: "",
        late_fee_type: "",
        late_fee_days: "",
        late_fee_subsequent_days: "",
        late_subsequent_fee: "",
        late_fee_subsequent_type: "",
        Unit: {
          Address: {},
        },
      },
      payment_methods: [],
      showAddMaintenance: false,
      sendMessage: false,
      showDeleteConfirmation: false,
      showEditLink: false,
      showMakePayment: false,
      showMakeInvoice: false,
      showManageAccess: false,
      showMoveOut: false,
      showAside: false,
      showTransfer: false,
      showEmailModal: false,
      textAreaHeight: 200,
      chatError: "",
      subject: "",
      hasScrollWindow: false,
      isStickyHeaderHide: false,
      // tenants:[],
      // activeTab: '',
      showNote: true,
      note: "",
      selection: [],
      tenant_contact: {},
      access: [],
      isSpaceAccessCodesVendor: false,
      spaceAccess: [],
      view: "tenantdocuments",
      activeTab: "tab-tenantdocuments",
      menuOptions: [
        {
          key: "tenantdocuments",
          label: "Tenant Documents",
        },
        {
          key: "archivedocuments",
          label: "Archived Documents",
        },
        {
          key: "documentlibrary",
          label: "Document Library",
        },
      ],
      propertyView: 'all-property',
      propertiesWithLeases: [],
      breadcrumbText: "Back to Previous Page",
      pageCreated: false,
      pending: [],
      contact_changing: false,
      contactEmailList: [],
      leasesDoc: "",
      currentPropertyId: "",
      mainViewOptions: [],
      mainView: {
        id: null,
        Unit: {
          number: "Tenant",
          type: "tenant",
        },
      },
      responsiveInteraction:false
    };
  },
  components: {
    Status,
    SendMessage,
    "send-email": sendEmail,
  },
  async mounted() {
    if (this.$route.query.unit_id) this.allPropertyView = false
    if (this.allPropertyView == true) {
      let path = `/national-account/${this.$route.params.contact_id}?na_id=${this.$route.query.na_id}&dynamicRun=true`;
      this.$router.push(path);
    }
    setTimeout(() => {
      this.$store.dispatch("charmCallStore/setLoadingAction", {
        status: false,
        eventStatus: "profile-button-clicked",
      });
    }, 2000);
    EventBus.$on("contact_updated_transfer", this.fetchContact);
    this.$store.dispatch("onBoardingStore/getNationalAccountKeystat", {
      contact_id: this.$route.params.contact_id,
      property_id: this.propertyView
    });
    window.addEventListener('scroll', this.handleScroll);
  },
  async created() {
    
    EventBus.$on('hideMiniprofile', this.hideMiniProfile)
    if (this.$route.query.unit_id) this.allPropertyView = false
    if (this.allPropertyView == true) {
      let path = `/national-account/${this.$route.params.contact_id}?na_id=${this.$route.query.na_id}&dynamicRun=true`;
      this.$router.push(path);
    }
    await this.loadTenantDetails();
    EventBus.$on("refetch_data", this.loadTenantDetails);
    EventBus.$on("contact_updated_for_mini_profile", this.fetchContact);
  },
  destroyed() {
    EventBus.$off("payment_methods_updated", this.fetchPaymentMethods);
    EventBus.$off("contact_updated_transfer", this.fetchContact);
    EventBus.$off("contact_updated", this.fetchContact);
    EventBus.$off("contact_updated_for_mini_profile", this.fetchContact);
    EventBus.$off("lease_payment_saved", this.fetchContact);
    EventBus.$off("payment_saved", this.fetchContact);
    // EventBus.$off('recordPaymentCanceled', this.recordPaymentCanceled);
    EventBus.$off("access_pin_modified", this.fetchAccess);
    EventBus.$off("space_access_modified", this.fetchContact);
    EventBus.$off("refresh-contact-profile", this.refreshPage);
    EventBus.$off("reload_tenant_profile_leases", this.fetchContact);
    EventBus.$off("delinquency_actions_update", this.updateDelinquencyActions);
    EventBus.$off("auto_pay_enrolment", this.autoPayEnrolled);
    EventBus.$off("refetch_data", this.loadTenantDetails);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleScroll);
    EventBus.$off('hideMiniprofile', this.hideMiniProfile);
  },
  computed: {
    ...mapGetters({
      isAdmin: "authenticationStore/isAdmin",
      isSuperAdmin: "authenticationStore/isSuperAdmin",
      properties: "propertiesStore/filtered",
      leasedProperties: "propertiesStore/leasedProperties",
      getRefreshTenantProfile: "tenantProfile/getRefreshTenantProfile",
      allProperties: "propertiesStore/properties",
      spaceAccessCodesMode: "authenticationStore/spaceAccessCodesMode",
      property: 'onBoardingStore/property',
      NationalAccount: 'onBoardingStore/getNationalAccount',
      hasPermission: "authenticationStore/rolePermission",
    }),
    filteredMenuOptions() {
      // Clone the menuOptions array to avoid mutating the original
      let options = [...this.menuOptions];

      // Check if propertyView is 'all-property' and remove the last item if true
      if (this.propertyView === 'all-property') {
        options.pop(); // Removes the last object
      }

      return options;
    },
    showSidebar() {
      return this.$route.meta.showSidebar;
    },

    property_leases() {
      // filters the leases that are available at the set property
      return this.contactLeases.filter(
        (cl) =>
          !this.propertyView ||
          this.propertyView === 'all-property' ||
          cl.Unit.property_id == this.propertyView
      );
    },
    properties_with_leases() {
      const filteredProperties = this.allProperties.filter((p) =>
        this.contactLeases.find((cl) => cl.Unit.property_id === p.id)
      );
      const newElement = {
        id: "all-property",
        name: "All Properties / Account Summary",
        number: "",
        Address: {
          id: "",
          address: "",
          address2: "",
          city: " ",
        },
      };
      if (this.hasPermission("manage_national_accounts")) filteredProperties.unshift(newElement);
      return filteredProperties;

      // return this.allProperties.filter((p) =>
      //   this.contactLeases.find((cl) => cl.Unit.property_id === p.id)
      // );
    },
    accountSize() {
      if (this.NationalAccount?.total_sqm) {
        return `${this.NationalAccount.total_sqm} sqm`;
      }
      return '';
    },
    computedBusinessAddress() {
      if (
        this.tenant_contact.Business &&
        this.tenant_contact.Business.Address
      ) {
        const address_1 = this.tenant_contact.Business.Address.address
          ? this.tenant_contact.Business.Address.address
          : "";
        const address_2 = this.tenant_contact.Business.Address.address2
          ? this.tenant_contact.Business.Address.address2
          : "";

        let address = address_1;
        if (address_2 !== "") {
          if (address !== "") {
            address += ", ";
          }
          address += address_2;
        }

        if (address !== "") {
          address += ", ";
        }

        address += this.$options.filters.formatAddress(this.tenant_contact.Business.Address);

        return address;
      } else {
        return undefined;
      }
    },

    calculateLength() {
      let leases = this.tenant_contact?.Leases.filter(lease => !lease.end_date)
      return leases?.filter((lease) => {
        return this.filterLeases(lease.Unit.property_id);
      })?.length;
    },
    showPaymentCyclesTab() {
      let leases = this.tenant_contact?.Leases;
      if (
        leases?.length &&
        leases.find((item) => item.PaymentCycleOptions.length > 0)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations({
      setFirstContact: "contactStore/setFirstContact",
      setPrimaryContactId: "contactStore/setPrimaryContactId",
      setCurrentProperty: "contactStore/setCurrentProperty",
      setRefreshTenantProfile: "tenantProfile/setRefreshTenantProfile",
      setProperty: "onBoardingStore/setProperty",
    }),
    handleScroll() {
        const scrollPosition = window.scrollY;
        this.hasScrollWindow = scrollPosition > 10;
    },
    hideMiniProfile(flag) {
                this.isStickyHeaderHide = flag && this.$vuetify.breakpoint.xs;
            },
    ...mapActions({
      setLeasedProperties: "propertiesStore/setLeasedProperties",

    }),
    responsiveInteractionAction(value){
        this.responsiveInteraction = value
    },
    closeDrawer () {
        if(this.$vuetify.breakpoint.width < 1366) {
            this.showAside = false;
            EventBus.$emit('hideNavigationDrawer', false);
        }
    },
    filterLeases(property_id) {
      return this.properties.some((i) => i.id === property_id);
    },
    handlePropertyViewChange() {
      this.overlay = true;
      setTimeout(() => {
        if (this.propertyView !== 'all-property') {
          this.allPropertyView = false;
          this.setProperty({ id: this.propertyView });
          let route
            route = `/national-account/${this.$route.params.contact_id}/account-profile?na_id=${this.$route.query.na_id}`;
          this.$router.push(route).then(() => {
            this.overlay = false;
          });
        } else {
          this.allPropertyView = true;
          let route
          if (this.$route.name == 'NationalDocuments') {
            route = `/national-account/${this.$route.params.contact_id}/files?na_id=${this.$route.query.na_id}`;
          }
          else {
            route = `/national-account/${this.$route.params.contact_id}?na_id=${this.$route.query.na_id}&dynamicRun=true`;
          }
          this.$router.push(route).then(() => {
            this.overlay = false;
          });
        }
        this.overlay = false;
      }, 1000); // Delay in milliseconds (1000ms = 1 second)
      this.$store.dispatch("onBoardingStore/getNationalAccountKeystat", {
        contact_id: this.$route.params.contact_id,
        property_id: this.propertyView
      });
    },
    setMoveOut(payload) {
      EventBus.$emit("move_out", {
        property_id: payload.Unit.property_id,
        contact: this.tenant_contact,
        lease_id: payload.id,
        unit_id: payload.unit_id,
      });
    },
    setTransfer(payload) {
      EventBus.$emit("transfer", {
        property_id: payload.Unit.property_id,
        contact: this.tenant_contact,
        lease_id: payload.id,
        unit_id: payload.unit_id,
        access: this.access,
        space_access: this.spaceAccess,
        is_space_access_codes_vendor: this.isSpaceAccessCodesVendor,
      });
    },
    setMerchandise(payload) {
      EventBus.$emit("sell_merchandise", {
        property_id: payload.Unit.property_id,
        contact: this.tenant_contact,
        lease_id: payload.id,
        unit_id: payload.unit_id,
        tab_type: payload.tab_type
      });
    },
    async loadTenantDetails() {
      EventBus.$emit("hide_drawer");
      await this.fetchProperties();
      await this.loadContactInfo();
      await this.fetchAccess();

      // await this.loadTenantDetails();
      if(this.$vuetify.breakpoint.width < 1366){
          this.showAside = false;
      } else {
          this.showAside = true;
      }
      // EventBus.$on("delinquency_actions_update", this.updateDelinquencyActions);
      EventBus.$on("contact_updated", this.fetchContact);
      // EventBus.$on("payment_methods_updated", this.fetchPaymentMethods);
      // EventBus.$on("lease_payment_saved", this.fetchContact);
      // EventBus.$on("payment_saved", this.fetchContact);
      // EventBus.$on('recordPaymentCanceled', this.recordPaymentCanceled);
      // EventBus.$on("access_pin_modified", this.fetchAccess);
      EventBus.$on("refresh-contact-profile", this.refreshPage);
      EventBus.$on("reload_tenant_profile_leases", this.fetchContact);
      // EventBus.$on("auto_pay_enrolment", this.autoPayEnrolled);

      this.pageCreated = true;
      this.setRefreshTenantProfile(false);
    },
    autoPayEnrolled(payload) {
      let { err } = payload;
      let errorMessage = err?.msg;
      if (errorMessage) {
        setTimeout(() => {
          this.showMessageNotification({ description: errorMessage });
        }, 3000);
      }
    },
    async fetchProperties() {
      await this.setLeasedProperties(this.$route.params.contact_id);
      if (this.leasedProperties.length === 0) {
        // this.$router.push('/national-accounts').catch(err => {})
      }
      if (!this.$route.query.unit_id) {
        this.propertyView = "all-property"
      } else {
        let property_id = this.$route.query.property_id;
        let unit_id = this.$route.query.unit_id;
        if (
          property_id &&
          this.leasedProperties.find((lp) => lp.id == property_id)
        )
          this.propertyView = property_id;
        else if (unit_id)
          this.propertyView = this.leasedProperties.find((lp) =>
            lp.Units?.includes(unit_id)
          )?.id;
        else if (this.properties?.length === 1)
          this.propertyView = this.properties[0].id;
        else this.propertyView = this.leasedProperties[0].id;
      }

    },
    async fetchContact(contact_id = "", updateLeases = false) {
      if (contact_id && contact_id !== this.$route.params.contact_id) return;
      let r = await api.get(this, api.CONTACTS + this.$route.params.contact_id);

      this.setFirstContact(r.contact);
      this.setPrimaryContactId(r.contact.id);
      this.setEmailList(r.contact);
      this.resetSpaceMenuOptions(r.contact);
      this.tenant_contact = r.contact;

      // if we are just updating the contact info, we dont need to reset these leases, otherwise there will be a dozen or so API requests for all units.

      if (!contact_id || updateLeases) {
        this.contactLeases = r.contact.Leases;
      }

      if (!this.contactLeases.length) return;
      if (this.$route.query.unit_id) {
        if (
          !this.propertyView ||
          !this.contactLeases.find(
            (cl) => cl.Unit.property_id === this.propertyView
          )
        ) {
          this.propertyView = this.contactLeases[0].Unit.property_id;
        }
      }

    },
    async fetchPending() {
      this.pending = [];
      await api
        .get(this, api.LEASES + "pending", {
          contact_id: this.$route.params.contact_id,
          property_id: this.propertyView,
        })
        .then((r) => {
          this.isLoaded = true;
          this.pending = r.leases.map((l) => {
            l.start_date = moment(l.start_date, "YYYY-MM-DD")
              .startOf("day")
              .valueOf();
            l.end_date = l.end_date
              ? moment(l.end_date, "YYYY-MM-DD").startOf("day").valueOf()
              : null;
            l.time = 0;
            l.contact = l.Tenants[0] ? l.Tenants[0].Contact : null;
            l.unit = l.Unit;
            l.task_type = "moveIn";
            return l;
          });
        });
    },
    async fetchPaymentMethods() {
      if (!this.propertyView) return;
      if (this.propertyView !== 'all-property') {
        let r = await api.get(
          this,
          api.CONTACTS +
          this.$route.params.contact_id +
          "/payment-methods?property_id=" +
          this.propertyView
        );
        this.payment_methods = r.payment_methods.map((pm) => {
          this.$set(pm, "editable", false);
          return pm;
        });
      }
    },
    async fetchAccess() {
      if (this.isSpaceAccessCodesVendor) {
        let s = await api.get(
          this,
          api.CONTACTS + this.$route.params.contact_id + "/space-access"
        );
        this.spaceAccess = s.access;
      } else {
        let r = await api.get(
          this,
          api.CONTACTS + this.$route.params.contact_id + "/access"
        );
        this.access = r.access;
      }
    },
    async checkIsSpaceAccessCodesVendor() {
      if (this.propertyView !== 'all-property') {
        try {
          let r = await api.get(
            this,
            api.PROPERTIES + this.propertyView + "/access"
          );

          if (r.property_access_name === "Derrels") {
            this.isSpaceAccessCodesVendor = true;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    viewContact(type) {
      if (type === "access") {
        this.$router.push(
          "/contacts/" + this.$route.params.contact_id + "/gate-access"
        );
      } else if (type === "payment-methods") {
        this.$router.push(
          "/contacts/" + this.$route.params.contact_id + "/payment_methods"
        );
      }
    },
    goBreadcrumbRoute() {
      const { name, params, query } = this.$route;
      if (name === "NationalAccountOverView") {
        this.$router.push("/national-accounts");
        return;
      }

      if(name === 'NationalAccountProfile'){
       if(this.breadcrumbText === 'Back to Previous Page' && this.allPropertyView){
        this.$router.push(`/national-account/${params.contact_id}?na_id=${query.na_id}&dynamicRun=true`);
        this.propertyView = "all-property";
        this.allPropertyView = true;
        return;
       }
       else{
        if (this.$route.path.includes('account-profile') && !this.allPropertyView) {
          this.propertyView = "all-property";
          this.allPropertyView = true;
        }
        this.$router.go(-1);
        return;
       }
      }

      const nationalRoutes = [
        "AccountInformation",
        "Communication",
        "NationalDocuments",
        "NationalAccountPayment",
      ];

      if (nationalRoutes.includes(name) && this.hasPermission("manage_national_accounts") && this.allPropertyView) {
        this.$router.push(`/national-account/${params.contact_id}?na_id=${query.na_id}&dynamicRun=true`);
        this.propertyView = "all-property";
        this.allPropertyView = true;
        return;
      }

      // Fallback for other cases
      this.$router.go(-1);
    },
    onChatError(error) {
      this.chatError = error;
    },
    onEmailClose() {
      this.showEmailModal = false;
      this.subject = "";
    },
    onEmailOpen() {
      this.showEmailModal = true;
    },
    refreshPage(contact_id = "") {
      this.clearData();
      this.loadContactInfo(contact_id);
      // this.$router.go(0);
    },
    async loadContactInfo(contact_id = "") {
      if (contact_id) {
        await this.fetchContact(contact_id, true);
      } else {
        await this.fetchContact();
      }
      //await this.checkIsSpaceAccessCodesVendor();
      //this.fetchAccess();
      //this.fetchPending();
      //this.fetchPaymentMethods();
      window.scrollTo(0, 0);
    },
    updateDelinquencyActions(payload) {
      const { leases } = payload;
      const isLeaseUpdated = this.contactLeases.some((cl) =>
        leases.some((l) => l.id === cl.id)
      );
      if (isLeaseUpdated) {
        this.fetchContact();
      }
    },
    clearData() {
      (this.contact_id = ""),
        //contactLeasesPreFilter: [],
        (this.contactLeases = []),
        (this.lease = {
          id: null,
          rent: 0,
          security_deposit: 0,
          type: "Fixed Length",
          start_date: "",
          end_date: "",
          billday: "",
          promotion_id: "",
          send_invoice: 1,
          notes: "",
          late_fee: "",
          late_fee_type: "",
          late_fee_days: "",
          late_fee_subsequent_days: "",
          late_subsequent_fee: "",
          late_fee_subsequent_type: "",
          Unit: {
            Address: {},
          },
        }),
        (this.property = {}),
        (this.note = "");
      this.selection = [];
      this.tenant_contact = {};
      this.access = [];
      this.propertyView = "";
      // this.propertiesWithLeases = [];
      this.pending = [];
    },
    phoneNumberClicked() {
      this.$refs.OverviewRef?.phoneNumberClicked();
    },
    setEmailList(contactObj) {
      this.contactEmailList = contactObj.Relationships.map((item) => {
        return {
          id: item.Contact.id,
          email: item.Contact.email + " (" + this.getEmailType(item) + ")",
        };
      });
      if (contactObj.Military.CommandingOfficer.email)
        this.contactEmailList.push({
          id: contactObj.Military.contact_id,
          email: contactObj.Military.CommandingOfficer.email + " (Military)",
        });
      this.contactEmailList.unshift({
        id: contactObj.id,
        email: contactObj.email + " (Primary)",
      });
    },
    getEmailType(emails) {
      const emailType = Object.keys(emails).find((key) => emails[key] === 1);
      switch (emailType) {
        case "is_alternate":
          return "Alternate";
          break;
        case "is_cosigner":
          return "Cosigner";
          break;
        case "is_emergency":
          return "Emergency";
          break;
        case "is_military":
          return "Military";
          break;
        case "is_authorized":
          return "Authorized";
          break;
        case "is_lien_holder":
          return "Lien Holder";
          break;
        default:
          return "";
          break;
      }
    },
    resetSpaceMenuOptions(contactObj) {
      var options = [
        {
          id: null,
          Unit: {
            number: "Tenant",
            type: "tenant",
          },
        },
      ];
      // below code is to consolidate all the spaces into one array.
      var allLeases = [];
      if (contactObj != null && contactObj != undefined) {
        if (
          contactObj.Leases != null &&
          contactObj.Leases != undefined &&
          contactObj.Leases.length > 0
        ) {
          let leases = contactObj.Leases;
          this.leasesDoc = leases;
          allLeases = [...allLeases, ...leases];
        }

        let reservations = contactObj.Reservations;
        if (
          reservations != null &&
          reservations != undefined &&
          reservations.length > 0
        ) {
          reservations.forEach((obj) => {
            allLeases.push(obj.Lease);
          });
        }

        let pending = contactObj.Pending;
        if (pending != null && pending != undefined && pending.length > 0) {
          pending.forEach((obj) => {
            allLeases.push(obj);
          });
        }
      }

      if (allLeases != null && allLeases != undefined) {
        allLeases.forEach((lease) => {
          var updatedValue = {
            id: lease.id,
            Unit: {
              number: lease.Unit.number,
              type: lease.Unit.type,
            },
          };
          options.push(updatedValue);
        });
      }

      (this.mainView = {
        id: null,
        Unit: {
          number: "Tenant",
          type: "tenant",
        },
      }),
        (this.mainViewOptions = options);
    },
  },
  watch: {
    "$route.params.contact_id"(new_contact_id) {
      this.clearData();
      this.loadContactInfo(new_contact_id);
      this.checkIsSpaceAccessCodesVendor();
      EventBus.$emit("hide_drawer");
    },
    $route() {
      if (this.$route.name === "NationalAccountOverView" || this.$route.name === "NationalAccountProfile") {
        this.breadcrumbText = "Back to Previous Page";
      } else if(this.allPropertyView) {
        this.breadcrumbText = "Back to Account Summary";
      }
      else{
        this.breadcrumbText = "Back to Account Profile";
      }
    },
    async propertyView() {
      if (this.propertyView !== 'all-property') {
        this.setCurrentProperty(this.propertyView);
      }
      // this.pending = [];
      // this.payment_methods = [];
      // this.fetchPending();
      // this.fetchPaymentMethods();
    },
    async getRefreshTenantProfile(value) {
      if (value) {
        await this.loadTenantDetails();
        this.$store.dispatch("onBoardingStore/getNationalAccountKeystat", {
          contact_id: this.$route.params.contact_id,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">

.main-content {
    overflow-y: auto;
    overflow-x: hidden;
}

.v-application .pa-0-im {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tenant-profile-header {
  padding-left: 0px !important;
  margin-left: -20px !important;
}

.tenant-profile-header .hb-settings-property-selector-wrapper {
  padding-left: 22px !important;
}

.sticky-miniprofile {
  padding-left: 25px !important;
  display: block !important;
}

.sticky-miniprofile hr.v-divider {
  border-color: transparent !important;
}

.tenant-profile-header .sticky-breadcumb {
  padding-left: 20px !important;
}

.tenant-profile-sticky .backtohistory a.hb-link {
  display: inline-block;
}

.tenant-profile-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.tenant-profile-shadow {
    box-shadow: 0 1px 3px rgba(110, 110, 110, 0.27);
}

.section-content {
  background: rgb(249, 250, 251);
}

.tenant-profile-sticky {
    position: sticky;
    top: 60px;
    z-index: 4;
    background-color: #f9fafb;
}

.main-section-heading {
  flex-direction: column;
}

.primary-section-content {
  display: flex;
  height: calc(100% - 70px);
  flex-direction: row;
  position: relative;
  overflow-y: hidden;
  & > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
  }
}

.attached-drawer {
  height: auto;
  background: transparent;
  padding: 0;
  width: 0;

  &.theme--light {
    &.v-navigation-drawer {
      background-color: none;
    }
  }

  &.attached-drawer--active {
    width: 400px;
  }
}

.tenant-profile-sidebar-wrapper .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active) {
  background-color: #f9fafb !important;
  border-left: 4px solid #f9fafb;
}

.tenant-profile-sidebar-wrapper .v-navigation-drawer__content a.v-list-item--active {
  background-color: #e2f2f3 !important;
  border-left: 4px solid #00848e;
}

.tenant-profile-sidebar-wrapper {
  z-index: 3!important;
}

.billing-information.section,
.lease-information.section {
  padding: 20px 20px;
}

.billing-information.section .container-fluid>.row {
  border-bottom: 1px solid #e2e2e2;
  padding-top: 15px;
  padding-bottom: 15px;
}

.billing-information.section .container-fluid>.row:last-child {
  border: none;
}

.lease-information.section .lease-img {
  text-align: center;
  margin-top: -100px;
  margin-bottom: 10px;
}

.billing-details,
.lease-details {
  font-size: 13px;
}

.lease-details .data-label {
  font-weight: 500;
  color: #abb1b9;
}

.lease-details .data-value {
  font-weight: 500;
  color: #263238;
}

.lease-details>.row {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
}

.lease-details>.row:last-child {
  border-bottom: none;
}

.billing-details>.row {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.unit-image {
  height: 135px;
  border: 5px solid white;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
  width: 240px;
  display: inline-block;
  margin: 10px;
  vertical-align: middle;
  padding: 0px;
  background-color: white;
  box-shadow: 0 0 10px #ccc;
  overflow: hidden;
}

.property-light-black {
  color: #677380;
}

.property-transparent {
  background: transparent;
}

.property-link {
  color: #306fb6;
  text-decoration: none;
}

.main-section-heading {
  padding-top: 5px;
  padding-bottom: 0;
}

.dot {
  height: 9px;
  width: 9px;
  margin: 1px 0 0 5px;
  background-color: #02ad0f;
  border-radius: 50%;
  display: inline-block;
}

.doterror {
  background-color: #cd2400;
}

.row-title {
  font-weight: 500;
}

.note-success {
  background-color: #e0f5f5;
  border: 1px solid #b3dee0;
}

.note-warning {
  background-color: #f8e5e1;
  border: 1px solid #f3cccc;
}

.lease-message {
  border-radius: 5px;
}

.row-title {
  font-weight: 500;
}

.loading {
  vertical-align: top;
  margin: 25% auto 0;

  .v-progress-circular {
    position: relative;
    top: 40%;
    left: 40%;
  }
}

.property-selector-gradient {
  background: repeating-linear-gradient(135deg,
      #ffd600,
      #ffd600 5px,
      #e0bd09 0px,
      #e0bd09 11px);
}

.section-content {
  padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0 !important;
    height: calc(100vh - 60px);
}

@media (min-width: 481px) and (max-width: 1259px) {

.tenant-profile-sidebar-wrapper {
  position: absolute;
  top: 0px;
}
}


@media  (max-width: 480px) {
  .tenant-profile-sticky {
      position: sticky;
      top: 60px;
      z-index: 2;
      background-color: #f9fafb;
  }
  
  .section-content {
      padding-left: 0px!important;
      padding-right: 0px!important;
  }
}

.fill-height-vh {
height: 100vh;
}

.height-auto {
height: 100%;
}

.is-email-container {
        height: calc(100% - 0px);
}


</style>
